import React from 'react';
import { Button, FormGroupList, Input, FormGroup, Textarea, DropdownList, } from 'tyb';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import { FormGroupField } from 'tyb';
const { createAddressbook, editAddressbook, loadAddressBookById } = iceStarkData.store.get('commonAction')?.addressBooks;
const { departmentSelections } = iceStarkData.store.get('commonAction')?.departments;
import './addressBookForm.scss';
import { Cascader } from 'antd';


const validate = values => {
  const errors = {};
  // 以数字字母开头，中间可以是多个数字字母下划线，@符号后面是数字字母，.后面跟2-4个字母结尾
  const regEmail = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
              
  // const regEmail = /^(([a-zA-Z]|[0-9])|(\.|\_|[a-zA-Z]|[0-9]))+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
  // 以1开头，11位数的数字
  const regMobile = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
  const regName = /^([a-z]|[A-Z]|[\·]|[\u4e00-\u9fa5]){1,10}$/;

  if (!values.name || values.name && values.name.trim().length == 0) {
    errors.name = "请输入姓名";
  }
  if (values.name && values.name.trim().length > 10) {
    errors.name = "输入字母，汉字，长度不超过10个字符";
  }
  if (values.name && !regName.test(values.name)) {
    errors.name = "请输入正确的姓名";
  }
  if (!values.mobile || values.mobile && values.mobile.trim().length == 0) {
    errors.mobile = "请输入手机号";
  }
  if (values.mobile && !regMobile.test(values.mobile)) {
    errors.mobile = "请输入正确的手机号";
  }
  if (!values.email || values.email && values.email.trim().length == 0) {
    errors.email = "请输入邮箱";
  }
  if (values.email && !regEmail.test(values.email)) {
    errors.email = "请输入正确的邮箱";
  }
 
  if (values.remark && values.remark.trim().length > 100) {
    errors.remark = '长度不超过100个字符';
  }
  return errors
};
@connect (
  state => {
    return {
      form: state.form,
      ...state.addressBooks,
      ...state.departments
    };
  },
  {
    createAddressbook: createAddressbook.REQUEST,
    editAddressbook: editAddressbook.REQUEST,
    loadAddressBookById: loadAddressBookById.REQUEST,
    departmentSelections: departmentSelections.REQUEST,
  }
)
class AddressForm extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      deptIds: [],
      departmentSelectionsTree: [],
      deptIdsError: ''
    };
  }
  componentDidMount () {
    const { dataId, loadAddressBookById, initialize, departmentSelections, departmentSelectionsList = [] } = this.props;
    const { departmentSelectionsTree = [] } = this.state;
    departmentSelections({ status: 'ENABLE' });
    if (departmentSelectionsTree.length < 1 && departmentSelectionsList.length > 0) {
      this.startDepartmentSelectionTree();
    }
    if (dataId) {
     loadAddressBookById ({
        id: dataId,
        callback: res => {
          initialize(res);
          if (res.deptId && departmentSelectionsList.length > 0) {
            if (departmentSelectionsTree.length < 1) {
              this.startDepartmentSelectionTree(true, res);
            } else {
              this.startInitDeptIds(departmentSelectionsTree)
            }
          }
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { addressBookDetail = {} } = this.props;
    if (prevProps.departmentSelectionsList.length == 0 && this.props.departmentSelectionsList.length > 0) {
      this.startDepartmentSelectionTree(true, addressBookDetail);
    }
  }
  // 调用组装部门树
  startDepartmentSelectionTree = (flag = false, IDDetailRes = {}) => {
    const { departmentSelectionsList = [] } = this.props;
    let departmentSelectionsTreeBridge = departmentSelectionsList.filter(item => item.level == 1 && item.status == 'ENABLE');
    this.initDepartmentSelectionsTree(departmentSelectionsList, departmentSelectionsTreeBridge);
    this.setState({ departmentSelectionsTree: departmentSelectionsTreeBridge });
    if(flag) this.startInitDeptIds(departmentSelectionsTreeBridge, IDDetailRes);
  }

  // 组装部门树
  initDepartmentSelectionsTree = (list, tree) => {
    tree.map(value => {
      value.children = list.filter(item => item.parentId == value.id && item.status == 'ENABLE');
      if (value.children.length) {
        this.initDepartmentSelectionsTree(list, value.children);
      } else {
        delete value.children;
      }
    })
  }

  // 开始组装部门选中结果
  startInitDeptIds = (departmentSelectionsTreeBridge, IDDetailRes = {}) => {
    let deptIds = [];
      this.initDeptIds(departmentSelectionsTreeBridge, IDDetailRes.deptId, deptIds);
      this.setState({ deptIds });
  }

  // 组装部门显示name数组
  initDeptIds = (list, value, deptIds = []) => {
    const { departmentSelectionsTree = [] } = this.state;
    list.map(item => {
      if (item.id == value) {
        if(!deptIds.includes(value))  deptIds.unshift(value);
        this.initDeptIds(departmentSelectionsTree, item.parentId, deptIds);
      } else if (item.children) {
        this.initDeptIds(item.children, value, deptIds);
      }
    })
  }


 // 统一去前后空格
 trims = obj => {
  for (let key in obj) {
    if(obj[key]==null){
    }
    else if (obj[key].constructor === String) {
      obj[key] = obj[key].trim();
    } else if (obj[key].constructor === Object) {
      this.trims(obj[key]);
    }
  }
}

  handleSubmit = data => {
    const {createAddressbook, editAddressbook, handleCancel} = this.props;
    const { deptIds = [] } = this.state;
    this.trims(data);
    if (deptIds.length < 1) {
      this.setState({deptIdsError: '请选择所属部门'})
    } else {
      data.deptId = deptIds[deptIds.length - 1];
      if (data.id) {
        editAddressbook ({data, callback: handleCancel});
      } else {
        createAddressbook ({data, callback: handleCancel});
      }
    }
  };

  
  render () {
    const { handleSubmit, handleCancel } = this.props;
    const { deptIds, deptIdsError, departmentSelectionsTree = [] } = this.state;
    return (
      <div className="addressbooks-form">
        <FormGroupList>
          <FormGroupField
            name={'name'}
            label="姓名"
            control={Input}
            component={FormGroup}
            // validate={this.brandNameValidator}
            required
            placeholder="请输入姓名"
            text="输入字母，汉字，长度不超过10个字符"
          />
        </FormGroupList>
        <div className="form-list deptId-box">
          <div style={ { display: "table-row" } }>
            <div className="form-label required deptId-label">
              <label>所属部门</label>
            </div>
            <div className="form-input deptId-input">
              <div className={deptIdsError ? "form-unit is-error" : "form-unit"}>
                <Cascader
                  value={deptIds}
                  className="antdCascader"
                  options={ departmentSelectionsTree }
                  fieldNames={ { label: 'name', value: 'id', children: 'children' } }
                  placeholder="请选择所属部门"
                  notFoundContent="暂无数据"
                  expandTrigger="hover"
                  changeOnSelect
                  allowClear={false}
                  onChange={ (value) => { this.setState({deptIds: value, deptIdsError: ''}); } }
                />
                <i className="tc-15-select deptId-icon"></i>
                <b className="icon-valid-flag" title={deptIdsError }></b>
              </div>
              <span className="deptIdsError-red">{ deptIdsError}</span>
            </div>
          </div>
        </div>
        <FormGroupList>
          <FormGroupField
            name={'mobile'}
            label="手机号"
            control={Input}
            component={FormGroup}
            required
          />
          <FormGroupField
            name={'email'}
            label="邮箱"
            control={Input}
            component={FormGroup}
            required
          />
          <FormGroupField
            name={'remark'}
            label="备注"
            control={Textarea}
            component={FormGroup}
            text="最多不超过100个字符"
          />
        </FormGroupList>
        <div className="btn-box">
        <Button onClick={ handleSubmit(this.handleSubmit) }>确定</Button>
          <Button className="weak" onClick={ ()=>{handleCancel()}}>取消</Button>
        </div>

      </div>
    );
  }
}
export default reduxForm ({
  form: 'AddressForm',
  validate
}) (AddressForm);
