import React from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
const {setNavBar} = iceStarkData.store.get('commonAction')?.ui;
const { updateAddressBookSearchKey, loadAddressBooksPageList, enableOrDisableAddressBooks, } = iceStarkData.store.get('commonAction')?.addressBooks;
const { departmentSelections } = iceStarkData.store.get('commonAction')?.departments;
import { Button, SearchInput, DataTable, Pager, Dialog, FormGroupList, StaticFormGroup, } from 'tyb';
import './index.scss';
import AddressBookForm from './components/addressBookForm.js';

@connect (
  state => {
    return {
      ...state.role,
      ...state.addressBooks,
      ...state.departments,
      permissionIds: state.users.permissionIds||[],
    };
  },
  {
    setNavBar,
    updateAddressBookSearchKey,
    loadAddressBooksPageList: loadAddressBooksPageList.REQUEST,
    enableOrDisableAddressBooks: enableOrDisableAddressBooks.REQUEST,
    departmentSelections: departmentSelections.REQUEST,
  }
)
class AddressBooks extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      showAddressBookModal: false,
    };
  }

  componentDidMount () {
    const {addressBookPageParam, loadAddressBooksPageList, departmentSelections} = this.props;
    loadAddressBooksPageList({ ...addressBookPageParam });
    departmentSelections();
  }

  // getTableColumns = () => {
    tableHeadArr = [
      {
        key: 'name',
        dataIndex: 'name',
        title: '姓名',
      },
      {
        key: 'deptName',
        dataIndex: 'deptName',
        title: '所属部门',
      },
      {
        key: 'mobile',
        dataIndex: 'mobile',
        title: '手机号',
      },
      {
        key: 'email',
        dataIndex: 'email',
        title: '邮箱',
      },
      {
        key: 'remark',
        dataIndex: 'remark',
        title: '备注',
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: '操作',
        render: (value, record) => (
          <div className="table-operate-btn" style={{color: '#006EFF'}}>
            {this.props.permissionIds.includes('data.organization.addressBook.edit') && <span onClick={() => { this.setState ({showAddressBookModal: true, dataId: record.id}); }} > 编辑 </span>}
            {this.props.permissionIds.includes('data.organization.addressBook.status') && <span onClick={() => { this.props.enableOrDisableAddressBooks ({ id: record.id, status: value == 'ENABLE' ? 'DISABLE' : 'ENABLE', }); }} > {value == 'ENABLE' ? '禁用' : '启用'} </span>}
          </div>
        ),
      },
    ];

  //   return tableHeadArr;
  // };

  tableHeadInit = () => {
    return this.tableHeadArr.map (column => {
      if (column.key === 'deptName') {
        return {
          ...column,
          headerRender: () => {
            const { addressBookPageParam = {}, departmentSelectionsList = [], loadAddressBooksPageList } = this.props;
            const valueNumberArr = [];
            if (addressBookPageParam['deptIds']) {
              addressBookPageParam['deptIds'].split(',').map(v => valueNumberArr.push(Number(v)));
            }
            return this.tableHeadFilter ({
              list: departmentSelectionsList,
              titleContent: '所属部门',
              value: valueNumberArr,
              filterFun: value => {
                const newValue = value.join (',');
                loadAddressBooksPageList ( { ...addressBookPageParam, deptIds: newValue == 0 ? '' : newValue, page: 0 });
              },
            });
          }
        };
      } else {
        return column;
      }
    });
  };

  tableHeadFilter = (param = {}) => {
    const {list = [], titleContent = '', value = '', filterFun} = param;
    return (
      <DataTable.FilterableColumnHeader
        list={list}
        value={value}
        onFilter={value => {
          filterFun && filterFun (value);
        }}
      >
        {titleContent}
      </DataTable.FilterableColumnHeader>
    );
  };

  toCreateAddressBook = () => {
    this.setState ({showAddressBookModal: true, dataId: ''});
  };

  render () {
    const { showAddressBookModal, dataId } = this.state;
    const { addressBookPageParam, addressBookSearchKey, addressBookPageList, addressBookPageListLoading, perPageCountList, loadAddressBooksPageList, updateAddressBookSearchKey, } = this.props;
    let listStyle = addressBookPageList.length > 0 && !addressBookPageListLoading;
    return (
      <div
        // className={`table-padding-bottom ${listStyle ? 'table-tr-height50' : 'table-tr-middle'}`}
        className={`table-padding-bottom table-tr-middle`}
      >
        <div className="headtop-box">
          {this.props.permissionIds.includes('data.organization.addressBook.add') && <Button onClick={this.toCreateAddressBook}>新增人员</Button>}
          <SearchInput
            className="addressbooks-searchkey"
            placeholder="输入姓名/手机号/邮箱搜索"
            onChange={value => {
              updateAddressBookSearchKey ({addressBookSearchKey: value});
            }}
            onSearch={value => {
              loadAddressBooksPageList ({...addressBookPageParam, page: 0});
            }}
            value={addressBookSearchKey}
          />
        </div>
        <DataTable
          columns={this.tableHeadInit ()}
          data={addressBookPageList}
          rowKey="id"
          rowClassName={record =>
            record.status == 'DISABLE' ? 'table-gray-row' : ''}
       
          messageRender={({data, error}) => {
            if (error != null) {
              return error.msg;
            }
            if (data == null || data.length === 0) {
              return (
                <span className="text-weak">
                  暂无人员{this.props.permissionIds.includes('data.organization.addressBook.add') ? '，您可以点击左上角' : null}
                  {this.props.permissionIds.includes('data.organization.addressBook.add') && <span onClick={this.toCreateAddressBook}>新增人员</span>}
                </span>
              );
            }
          }}
          loading={addressBookPageListLoading}
        />
        {listStyle &&
          <div className="page-padding20">
            <Pager
              total={addressBookPageParam.totalElements}
              current={addressBookPageParam.page}
              perPageCount={addressBookPageParam.size}
              perPageCountList={perPageCountList}
              onPageChange={e => {
                loadAddressBooksPageList ({...addressBookPageParam, page: e});
              }}
              onPerPageChange={e => {
                loadAddressBooksPageList ({
                  ...addressBookPageParam,
                  size: e,
                  page: 0,
                });
              }} // 每页大小
            />
          </div>}

        <Dialog
          className="dialog-has-ok"
          hasFooter={ false }
          title={ dataId ? '编辑人员' : '新增人员' }
          visible={ showAddressBookModal }
          onCancel={ () => {
            this.setState({ showAddressBookModal: false });
          } }
        >
          <AddressBookForm dataId={dataId} handleCancel={() => {
            this.setState({ showAddressBookModal: false });
          } }/>
        </Dialog>
      </div>
    );
  }
}

export default AddressBooks;
